<template>
  <v-template>
    <div class="scollup-btn hideOnMd">
      <v-icon v-if="mockupVer !== 0" large @click="scrollTo(0)">mdi-chevron-up</v-icon>
    </div>
    <v-main>
      <v-app-bar class="head-nav2" fixed dark inverted-scroll>
        <a href="/" style="height: 100%">
          <img :src="require('@/assets/logo2.png')" style="height: 50px" />
        </a>
        <v-spacer></v-spacer>
        <a
          class="gplay-wrap"
          href="https://play.google.com/store/apps/details?id=com.stonks.app&amp;pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1"
          target="_blank"
        >
          <img :src="require('@/assets/gplay.png')" alt="Google Play Download Logo" />
        </a>
        <v-spacer></v-spacer>
        <v-icon @click="darkMode" title="Dark Mode">mdi-theme-light-dark</v-icon>
      </v-app-bar>
      <div class="bg-wrapper" style="z-index: 0 !important">
        <v-container :fluid="$vuetify.breakpoint.lgAndDown" px-0 pt-0>
          <v-row justify="center" class="pt-0 mt-0" no-gutters>
            <v-col cols="12" class="ovh">
              <div class="bgHolder">
                <img :src="require('@/assets/bg1/bg0.png')" class="bhImg doFloat2" />
              </div>
            </v-col>
          </v-row>
        </v-container>
      </div>
      <div class="bg-wrapper" style="z-index: 3 !important">
        <v-container :fluid="$vuetify.breakpoint.lgAndDown" px-0 pt-0>
          <v-row justify="center" class="pt-0 mt-0" no-gutters>
            <v-col cols="12" class="ovh">
              <div class="bgHolder">
                <img :src="require('@/assets/bg1/bg2.png')" class="bhImg doFloat2" />
              </div>
            </v-col>
          </v-row>
        </v-container>
      </div>
      <div class="bg-wrapper" style="z-index: 5 !important">
        <v-container :fluid="$vuetify.breakpoint.lgAndDown" px-0 pt-0>
          <v-row justify="center" class="pt-0 mt-0" no-gutters>
            <v-col cols="12">
              <div class="bgHolder noncropped">
                <img :src="require('@/assets/bg1/bg3.png')" class="bhImg doFloat1" />
              </div>
            </v-col>
          </v-row>
        </v-container>
      </div>

      <div class="mini-bg" style="z-index: 1 !important">
        <v-container :fluid="$vuetify.breakpoint.lgAndDown" px-0 pt-0>
          <v-row justify="center" class="pt-0 mt-0" no-gutters>
            <v-col cols="12" class="ovh">
              <div class="minHolder">
                <img :src="require('@/assets/bg1/minAd01.png')" />
              </div>
            </v-col>
          </v-row>
        </v-container>
      </div>

      <v-container fluid pt-0>
        <v-container :fluid="$vuetify.breakpoint.lgAndDown" pt-0>
          <v-row justify="center" class="intro-bg" no-gutters style="z-index: 3 !important">
            <v-col cols="12" class="ovh">
              <div class="bgHolder"></div>
            </v-col>
          </v-row>
          <v-row justify="center" no-gutters style="z-index: 3 !important" class="d-flex d-md-none">
            <v-col cols="12" class="ovh">
              <div class="min-spacer">
                <a
                  href="https://play.google.com/store/apps/details?id=com.stonks.app&amp;pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1"
                  target="_blank"
                >
                  <img :src="require('@/assets/bg1/minAd00.png')" />
                </a>
              </div>
            </v-col>
          </v-row>
          <v-row justify="center" no-gutters style="z-index: 3 !important" class="d-flex d-md-none">
            <v-col cols="12">
              <div class="spacer-bar">
                <div></div>
              </div>
            </v-col>
          </v-row>

          <v-row justify="center" class="sticky-wrapper pt-0" no-gutters style="z-index: 2 !important" :class="{ 'mb-50': mockupVer == 3 }">
            <v-col cols="12" :class="`mockup-ver${mockupVer}`">
              <div class="mockup-wrap">
                <div class="mockup-wrap2">
                  <div class="mp mp4">
                    <figure class="frame frame-minimal">
                      <img :src="require('@/assets/arrows2.png')" alt="Stonks Arrows" />
                    </figure>
                  </div>
                  <div class="mp mp3">
                    <figure class="frame frame-minimal-phone">
                      <img :src="require('@/assets/mockups/5a.jpg')" v-show="$vuetify.theme.dark" alt="StonksPro App Leaderboards Page Dark" />
                      <img :src="require('@/assets/mockups/5b.jpg')" v-show="!$vuetify.theme.dark" alt="StonksPro App Leaderboards Page Light" />
                    </figure>
                  </div>
                  <div class="mp mp2">
                    <figure class="frame frame-minimal-phone">
                      <img :src="require('@/assets/mockups/4a.jpg')" v-show="$vuetify.theme.dark" alt="StonksPro App Achievements Page Dark" />
                      <img :src="require('@/assets/mockups/4b.jpg')" v-show="!$vuetify.theme.dark" alt="StonksPro App Achievements Page Light" />
                    </figure>
                  </div>
                  <div class="mp mp1">
                    <figure class="frame frame-minimal-phone">
                      <span v-show="mockupVer == 0">
                        <img :src="require('@/assets/mockups/1a.jpg')" v-show="$vuetify.theme.dark" alt="StonksPro App Home Page Dark Mode" />
                        <img :src="require('@/assets/mockups/1b.jpg')" v-show="!$vuetify.theme.dark" alt="StonksPro App Home Page Light Mode" />
                      </span>
                      <span v-show="mockupVer == 1">
                        <img :src="require('@/assets/mockups/2a.jpg')" v-show="$vuetify.theme.dark" alt="StonksPro App Meme Shares Info Dialog Dark" />
                        <img :src="require('@/assets/mockups/2b.jpg')" v-show="!$vuetify.theme.dark" alt="StonksPro App Meme Shares Info Dialog Light" />
                      </span>
                      <span v-show="mockupVer == 2">
                        <img :src="require('@/assets/mockups/3a.jpg')" v-show="$vuetify.theme.dark" alt="StonksPro App Account Page Dark" />
                        <img :src="require('@/assets/mockups/3b.jpg')" v-show="!$vuetify.theme.dark" alt="StonksPro App Account Page Light" />
                      </span>
                      <div v-show="mockupVer >= 3">
                        <div v-show="info == 0">
                          <img :src="require('@/assets/mockups/7a.png')" v-show="$vuetify.theme.dark" alt="StonksPro App Navigation Overlay Dark" />
                          <img :src="require('@/assets/mockups/7b.png')" v-show="!$vuetify.theme.dark" alt="StonksPro App Navigation Overlay Light" />

                          <div class="scrollLayerwrap">
                            <img class="scrollAni" :src="require('@/assets/mockups/8a.jpg')" v-show="$vuetify.theme.dark" alt="StonksPro App Settings Page Dark" />
                            <img class="scrollAni" :src="require('@/assets/mockups/8b.jpg')" v-show="!$vuetify.theme.dark" alt="StonksPro App Settings Page Light" />
                          </div>
                        </div>
                        <div v-show="info == 1">
                          <img :src="require('@/assets/mockups/9a.jpg')" v-show="$vuetify.theme.dark" alt="StonksPro App Customize Subreddit Dark" />
                          <img :src="require('@/assets/mockups/9b.jpg')" v-show="!$vuetify.theme.dark" alt="StonksPro App Customize Subreddit Light" />
                        </div>
                        <div v-show="info == 2">
                          <img :src="require('@/assets/mockups/10a.jpg')" v-show="$vuetify.theme.dark" alt="StonksPro App Shares History Dark" />
                          <img :src="require('@/assets/mockups/10b.jpg')" v-show="!$vuetify.theme.dark" alt="StonksPro App Shares History Light" />
                        </div>
                        <div v-show="info == 3">
                          <img :src="require('@/assets/mockups/11a.jpg')" v-show="$vuetify.theme.dark" alt="Memes Info Dialog with Share Feature Dark" />
                          <img :src="require('@/assets/mockups/11b.jpg')" v-show="!$vuetify.theme.dark" alt="Memes Info Dialog with Share Feature Light" />
                        </div>
                        <div v-show="info == 4">
                          <img :src="require('@/assets/mockups/12a.jpg')" v-show="$vuetify.theme.dark" alt="StonksPro App Recruit Page Dark" />
                          <img :src="require('@/assets/mockups/12b.jpg')" v-show="!$vuetify.theme.dark" alt="StonksPro App Recruit Page Light" />
                        </div>
                        <div v-show="info == 5">
                          <img :src="require('@/assets/mockups/14a.png')" v-show="$vuetify.theme.dark" alt="StonksPro App Navigation Overlay Dark" />
                          <img :src="require('@/assets/mockups/14b.png')" v-show="!$vuetify.theme.dark" alt="StonksPro App Navigation Overlay Light" />

                          <div class="scrollLayerwrap">
                            <img class="scrollAni2" :src="require('@/assets/mockups/13a.jpg')" v-show="$vuetify.theme.dark" alt="StonksPro App Settings Page Dark" />
                            <img class="scrollAni2" :src="require('@/assets/mockups/13b.jpg')" v-show="!$vuetify.theme.dark" alt="StonksPro App Settings Page Light" />
                          </div>
                        </div>
                      </div>
                    </figure>
                  </div>
                </div>
              </div>
            </v-col>
          </v-row>

          <v-row justify="center" class="head-nav" no-gutters :style="`z-index: ${dockNav ? '5' : '3'}!important`">
            <v-col cols="12" align-self="stretch">
              <div class="nav-wrap">
                <div class="nav-bg" :class="{ docked: dockNav }">
                  <div class="elevation-5"></div>
                </div>
                <div class="nav-fg d-flex" :class="{ docked: !dockNav }">
                  <v-spacer class="nav-sp" :class="{ docked: !dockNav }"></v-spacer>
                  <div class="logo-wrap">
                    <a href="/" target="_blank">
                      <img :src="require('@/assets/logo2.png')" class="elevation-5" />
                    </a>
                  </div>
                  <div class="play-wrap">
                    <a
                      href="https://play.google.com/store/apps/details?id=com.stonks.app&amp;pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1"
                      target="_blank"
                    >
                      <img src="https://play.google.com/intl/en_us/badges/static/images/badges/en_badge_web_generic.png" alt="Google Play Download Logo" />
                    </a>
                  </div>
                  <v-spacer class="nav-sp" :class="{ docked: dockNav }"></v-spacer>
                  <div class="nav-btn mr-3">
                    <v-icon dark @click="darkMode" title="Dark Mode">mdi-theme-light-dark</v-icon>
                  </div>
                </div>
              </div>
              <div class="d-flex justify-center" v-if="!dockNav"><v-icon large @click="scrollTo(700)">mdi-chevron-down</v-icon></div>
            </v-col>
          </v-row>
          <div style="height: 70px" class="hideOnMd"></div>

          <v-row>
            <v-col cols="12">
              <div class="d-flex d-md-none justify-center">
                <div class="mockup-wrap4 v1">
                  <figure class="frame frame-minimal">
                    <img :src="require('@/assets/arrows2.png')" alt="screenshot" />
                  </figure>
                  <figure class="frame frame-minimal-phone a1">
                    <div>
                      <img :src="require('@/assets/mockups/2a.jpg')" v-show="$vuetify.theme.dark" alt="StonksPro App Meme Shares Info Dialog Dark" />
                      <img :src="require('@/assets/mockups/2b.jpg')" v-show="!$vuetify.theme.dark" alt="StonksPro App Meme Shares Info Dialog Light" />
                    </div>
                  </figure>
                  <figure class="frame frame-minimal-phone a2">
                    <div>
                      <img :src="require('@/assets/mockups/3a.jpg')" v-show="$vuetify.theme.dark" alt="StonksPro App Account Page Dark" />
                      <img :src="require('@/assets/mockups/3b.jpg')" v-show="!$vuetify.theme.dark" alt="StonksPro App Account Page Light" />
                    </div>
                  </figure>
                </div>
              </div>
            </v-col>
          </v-row>

          <v-row justify="center" ref="pos2" class="mt-md-5 mt-n15">
            <v-col cols="5" class="hideOnMd">
              <v-card height="1000px" color="transparent" flat></v-card>
            </v-col>
            <v-col cols="12" md="5">
              <v-card color="transparent" flat class="card-wrap">
                <div class="ml-0 ml-md-0">
                  <v-card outlined>
                    <v-card-text class="pb-0">
                      <div class="igw2">
                        <div class="iconGridsWrap tborder2 h80 mb-2">
                          <div class="iconGrid v4x4 pa-1">
                            <v-icon x-large style="grid-area: 2 / 2 / 4 / 4" class="">fa-rocket</v-icon>
                            <v-icon small style="grid-area: 3 / 3 / 5 / 5">fa-check-circle</v-icon>
                          </div>
                        </div>
                      </div>

                      <p class="text-h5 text-md-h4 text--primary font-weight-medium text-center text-md-left">Blast through Memes TO THE MOON!</p>
                      <div class="subtitle-1">
                        <p>
                          Browse all the best dank memes and funny pics while you learn to invest.
                          <br />
                          Become the best amongst our Leaderboards and your Friends.
                        </p>
                      </div>
                    </v-card-text>
                  </v-card>
                </div>
                <div class="ml-0 ml-md-5">
                  <v-card outlined>
                    <v-card-text class="pb-0">
                      <div class="igw2">
                        <div class="iconGridsWrap tborder2 h80 mb-2">
                          <div class="iconGrid v4x4 pa-1">
                            <v-icon x-large style="grid-area: 3 / 1 / 5 / 4">fa-chart-line</v-icon>
                            <v-icon style="grid-area: 1 / 3 / 4 / 5; transform: rotate(8deg) translateX(5px)">fa-coins</v-icon>
                            <v-icon style="grid-area: 1 / 1 / 3 / 3; transform: rotate(-8deg)">fa-grin-tears</v-icon>
                          </div>
                        </div>
                      </div>
                      <p class="text-h5 text-md-h4 text--primary font-weight-medium text-center text-md-left">Suffer From Success.</p>
                      <div class="subtitle-1">
                        <p>
                          Master the markets with your overpowered meme knowledge <br />
                          and grow your portfolio to infinity. <br />
                          Money printer go brrrrrr.
                        </p>
                      </div>
                    </v-card-text>
                  </v-card>
                </div>
                <div class="ml-0 ml-md-10">
                  <v-card outlined>
                    <v-card-text class="pb-0">
                      <div class="igw2">
                        <div class="iconGridsWrap tborder2 h80 mb-2">
                          <div class="iconGrid v4x4 pa-1">
                            <v-icon style="grid-area: 1 / 1 / 3 / 2; transform: rotate(-15deg) translateY(-4px) scale(1.15)" small>fa-trophy</v-icon>
                            <v-icon style="grid-area: 1 / 2 / 3 / 4; transform: translateX(1px) translateY(-5px) scale(1.2)">mdi-emoticon-cool</v-icon>
                            <v-icon style="grid-area: 1 / 4 / 3 / 5; transform: rotate(15deg) translateY(-4px) scale(1.2)" small>fa-dollar-sign</v-icon>
                            <v-icon style="grid-area: 3 / 3 / 5 / 5; transform: rotate(12deg) translateX(8px) translateY(5px)">fa-surprise</v-icon>
                            <v-icon style="grid-area: 3 / 1 / 5 / 3; transform: rotate(-12deg) translateX(-8px) translateY(5px)">fa-grin-hearts</v-icon>
                          </div>
                        </div>
                      </div>
                      <p class="text-h5 text-md-h4 text--primary font-weight-medium text-center text-md-left">Achieve the unachievable.</p>
                      <div class="subtitle-1">
                        <p>
                          Go beyond your limits and collect all unbelievable Achievement-Rings. <br />
                          Weird flexes allowed.
                        </p>
                      </div>
                    </v-card-text>
                  </v-card>
                </div>
                <div v-if="mockupVer == 1" style="transform: translateX(calc(-50% - 12px))" class="d-none d-md-flex justify-center">
                  <v-icon large @click="scrollTo($refs.pos3.offsetTop - 100)">mdi-chevron-down</v-icon>
                </div>
              </v-card>
            </v-col>
          </v-row>

          <v-row>
            <v-col col="12">
              <div class="d-flex d-md-none justify-center">
                <div class="mockup-wrap4 v2">
                  <figure class="frame frame-minimal-phone a1">
                    <div>
                      <img :src="require('@/assets/mockups/3a.jpg')" v-show="$vuetify.theme.dark" alt="StonksPro App Account Page Dark" />
                      <img :src="require('@/assets/mockups/3b.jpg')" v-show="!$vuetify.theme.dark" alt="StonksPro App Account Page Light" />
                    </div>
                  </figure>
                  <figure class="frame frame-minimal-phone a2">
                    <div>
                      <img :src="require('@/assets/mockups/4a.jpg')" v-show="$vuetify.theme.dark" alt="StonksPro App Achievements Page Dark" />
                      <img :src="require('@/assets/mockups/4b.jpg')" v-show="!$vuetify.theme.dark" alt="StonksPro App Achievements Page Light" />
                    </div>
                  </figure>
                  <figure class="frame frame-minimal-phone a3">
                    <div>
                      <img :src="require('@/assets/mockups/5a.jpg')" v-show="$vuetify.theme.dark" alt="StonksPro App Leaderboards Page Dark" />
                      <img :src="require('@/assets/mockups/5b.jpg')" v-show="!$vuetify.theme.dark" alt="StonksPro App Leaderboards Page Light" />
                    </div>
                  </figure>
                </div>
              </div>
            </v-col>
          </v-row>

          <v-row justify="center" ref="pos3">
            <v-col cols="12" md="5">
              <v-card color="transparent" flat class="card-wrap">
                <div class="mr-0">
                  <v-card outlined>
                    <v-card-text class="pb-0 d-flex flex-column align-md-end">
                      <div class="igw2">
                        <div class="iconGridsWrap tborder2 h80 mb-2">
                          <div class="iconGrid v4x4 pa-1">
                            <v-icon x-large style="grid-area: 1 / 1 / 4 / 4" class="">fa-tasks</v-icon>
                            <v-icon x-large style="grid-area: 3 / 2 / 5 / 5">mdi-trending-up</v-icon>
                          </div>
                        </div>
                      </div>

                      <p class="text-h5 text-md-h4 text--primary font-weight-medium text-center text-md-left">Manage Your Portfolio</p>
                      <div class="subtitle-1">
                        <p>Watch your Portfolio value rise and decide when to sell your shares.</p>
                      </div>
                    </v-card-text>
                  </v-card>
                </div>
                <div class="mr-0 mr-md-5">
                  <v-card outlined>
                    <v-card-text class="pb-0 d-flex flex-column align-md-end">
                      <div class="igw2">
                        <div class="iconGridsWrap tborder2 h80 mb-2">
                          <div class="iconGrid v4x4 pa-1">
                            <v-icon top x-large style="grid-area: 1 / 2 / 4 / 4; transform: translateY(-5px)" class="">fa-user-circle</v-icon>
                            <v-icon left style="grid-area: 3 / 1 / 5 / 3">fa-award</v-icon>
                            <v-icon style="grid-area: 3 / 2 / 5 / 4; transform: translateY(5px)">fa-award</v-icon>
                            <v-icon right style="grid-area: 3 / 3 / 5 / 5">fa-award</v-icon>
                          </div>
                        </div>
                      </div>

                      <p class="text-h5 text-md-h4 text--primary font-weight-medium text-center text-md-left">Achievement-Rings</p>
                      <div class="subtitle-1">
                        <p>
                          Add flavor to your profile picture with unlockable Achievement-Rings.
                          <br />
                          Complete Goals, Riddles or buy with ingame Power Points.
                        </p>
                      </div>
                    </v-card-text>
                  </v-card>
                </div>
                <div class="mr-0 mr-md-10">
                  <v-card outlined>
                    <v-card-text class="pb-0 d-flex flex-column align-md-end">
                      <div class="igw2">
                        <div class="iconGridsWrap tborder2 h80 mb-2">
                          <div class="iconGrid v4x4 pa-1">
                            <v-icon large style="grid-area: 1 / 1 / 3 / 3; transform: rotate(-5deg)">fa-chess-queen</v-icon>
                            <v-icon large style="grid-area: 3 / 3 / 5 / 5" class="">fa-stream</v-icon>
                          </div>
                        </div>
                      </div>

                      <p class="text-h5 text-md-h4 text--primary font-weight-medium text-center text-md-left">Leaderboards</p>
                      <div class="subtitle-1">
                        <p>
                          Compete for the highest Rank among the World or your Friends.
                          <br />
                          Create your own Board. See recently sold Shares and unlocked Achievement.
                        </p>
                      </div>
                    </v-card-text>
                  </v-card>
                </div>
              </v-card>
              <div v-if="mockupVer == 2" style="transform: translateX(calc(50% + 12px))" class="d-none d-md-flex justify-center">
                <v-icon large @click="scrollTo($refs.pos3.offsetTop - 50 + $refs.pos2.clientHeight)">mdi-chevron-down</v-icon>
              </div>
            </v-col>
            <v-col cols="5" class="hideOnMd">
              <v-card height="1000px" color="transparent" flat></v-card>
            </v-col>
          </v-row>

          <v-row justify="center">
            <v-col cols="2" md="4" class="pa-1">
              <v-card height="420px" outlined class="d-none d-md-flex flex-column justify-space-around text-right">
                <v-list-item three-line @click="info = 0" :class="{ 'v-list-item--active': info == 0 }">
                  <v-list-item-content>
                    <v-list-item-title class="text-h5 text--primary font-weight-medium">Settings</v-list-item-title>
                    <v-list-item-subtitle class="subtitle-1">Find useful Settings such as Language, Used Reddit Markets, Analytics Preference and FAQ.</v-list-item-subtitle>
                    <v-list-item-subtitle class="subtitle-1">Supported Languages are: English, German, Korean, Spanish, Portuguese, French</v-list-item-subtitle>
                  </v-list-item-content>
                  <v-list-item-action class="align-self-center">
                    <v-icon large>fa-cog</v-icon>
                  </v-list-item-action>
                </v-list-item>
                <v-divider></v-divider>

                <v-list-item three-line @click="info = 1" :class="{ 'v-list-item--active': info == 1 }">
                  <v-list-item-content>
                    <v-list-item-title class="text-h5 text--primary font-weight-medium">Custom Subreddits</v-list-item-title>
                    <v-list-item-subtitle class="subtitle-1">Decide in which Subreddits you want to invest.</v-list-item-subtitle>
                    <v-list-item-subtitle class="subtitle-1">Add, Delete or disable any Subreddit.</v-list-item-subtitle>
                  </v-list-item-content>
                  <v-list-item-action class="align-self-center">
                    <v-icon large>fa-tools</v-icon>
                  </v-list-item-action>
                </v-list-item>
                <v-divider></v-divider>

                <v-list-item three-line @click="info = 2" :class="{ 'v-list-item--active': info == 2 }">
                  <v-list-item-content>
                    <v-list-item-title class="text-h5 text--primary font-weight-medium">History</v-list-item-title>
                    <v-list-item-subtitle class="subtitle-1">See all your previously sold Shares.</v-list-item-subtitle>
                    <v-list-item-subtitle class="subtitle-1">Tapping the Picture in each row reveals more details.</v-list-item-subtitle>
                  </v-list-item-content>
                  <v-list-item-action class="align-self-center">
                    <v-icon large>fa-history</v-icon>
                  </v-list-item-action>
                </v-list-item>
              </v-card>
              <v-card height="420px" outlined class="d-flex d-md-none flex-column justify-space-around">
                <v-list-item three-line @click="info = 0" class="d-flex justify-center" :class="{ 'v-list-item--active': info == 0 }">
                  <v-icon>fa-cog</v-icon>
                </v-list-item>
                <v-divider></v-divider>

                <v-list-item three-line @click="info = 1" class="d-flex justify-center" :class="{ 'v-list-item--active': info == 1 }">
                  <v-icon>fa-tools</v-icon>
                </v-list-item>
                <v-divider></v-divider>

                <v-list-item three-line @click="info = 2" class="d-flex justify-center" :class="{ 'v-list-item--active': info == 2 }">
                  <v-icon>fa-history</v-icon>
                </v-list-item>
              </v-card>
            </v-col>
            <v-col cols="8" md="2" class="pa-0">
              <v-card class="infoMidCard" color="transparent" flat>
                <div class="d-block d-md-none">
                  <figure class="frame frame-minimal-phone">
                    <div class="mockup-wrap3">
                      <div v-show="info == 0">
                        <img :src="require('@/assets/mockups/7a.png')" v-show="$vuetify.theme.dark" alt="StonksPro App Navigation Overlay Dark" />
                        <img :src="require('@/assets/mockups/7b.png')" v-show="!$vuetify.theme.dark" alt="StonksPro App Navigation Overlay Light" />

                        <div class="scrollLayerwrap">
                          <img class="scrollAni" :src="require('@/assets/mockups/8a.jpg')" v-show="$vuetify.theme.dark" alt="StonksPro App Settings Page Dark" />
                          <img class="scrollAni" :src="require('@/assets/mockups/8b.jpg')" v-show="!$vuetify.theme.dark" alt="StonksPro App Settings Page Light" />
                        </div>
                      </div>
                      <div v-show="info == 1">
                        <img :src="require('@/assets/mockups/9a.jpg')" v-show="$vuetify.theme.dark" alt="StonksPro App Customize Subreddit Dark" />
                        <img :src="require('@/assets/mockups/9b.jpg')" v-show="!$vuetify.theme.dark" alt="StonksPro App Customize Subreddit Light" />
                      </div>
                      <div v-show="info == 2">
                        <img :src="require('@/assets/mockups/10a.jpg')" v-show="$vuetify.theme.dark" alt="StonksPro App Shares History Dark" />
                        <img :src="require('@/assets/mockups/10b.jpg')" v-show="!$vuetify.theme.dark" alt="StonksPro App Shares History Light" />
                      </div>
                      <div v-show="info == 3">
                        <img :src="require('@/assets/mockups/11a.jpg')" v-show="$vuetify.theme.dark" alt="Memes Info Dialog with Share Feature Dark" />
                        <img :src="require('@/assets/mockups/11b.jpg')" v-show="!$vuetify.theme.dark" alt="Memes Info Dialog with Share Feature Light" />
                      </div>
                      <div v-show="info == 4">
                        <img :src="require('@/assets/mockups/12a.jpg')" v-show="$vuetify.theme.dark" alt="StonksPro App Recruit Page Dark" />
                        <img :src="require('@/assets/mockups/12b.jpg')" v-show="!$vuetify.theme.dark" alt="StonksPro App Recruit Page Light" />
                      </div>
                      <div v-show="info == 5">
                        <img :src="require('@/assets/mockups/14a.png')" v-show="$vuetify.theme.dark" alt="StonksPro App Feed Overlay Dark" />
                        <img :src="require('@/assets/mockups/14b.png')" v-show="!$vuetify.theme.dark" alt="StonksPro App Feed Overlay Light" />

                        <div class="scrollLayerwrap">
                          <img class="scrollAni2" :src="require('@/assets/mockups/13a.jpg')" v-show="$vuetify.theme.dark" alt="StonksPro App Feed Page Dark" />
                          <img class="scrollAni2" :src="require('@/assets/mockups/13b.jpg')" v-show="!$vuetify.theme.dark" alt="StonksPro App Feed Page Light" />
                        </div>
                      </div>
                    </div>
                  </figure>
                </div>
              </v-card>
            </v-col>
            <v-col cols="2" md="4" class="pa-1">
              <v-card height="420px" outlined class="d-none d-md-flex flex-column justify-space-around">
                <v-list-item three-line @click="info = 3" :class="{ 'v-list-item--active': info == 3 }">
                  <v-list-item-action class="align-self-center">
                    <v-icon large>fa-share-alt</v-icon>
                  </v-list-item-action>
                  <v-list-item-content>
                    <v-list-item-title class="text-h5 text--primary font-weight-medium">Share & Visit</v-list-item-title>
                    <v-list-item-subtitle class="subtitle-1">Jump directly to the Reddit Page of any Meme.</v-list-item-subtitle>
                    <v-list-item-subtitle class="subtitle-1">Share any Meme with a generated short link.</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
                <v-divider></v-divider>

                <v-list-item three-line @click="info = 4" :class="{ 'v-list-item--active': info == 4 }">
                  <v-list-item-action class="align-self-center">
                    <v-icon large>fa-coins</v-icon>
                  </v-list-item-action>
                  <v-list-item-content>
                    <v-list-item-title class="text-h5 text--primary font-weight-medium">Recruit Friends for Rewards</v-list-item-title>
                    <v-list-item-subtitle class="subtitle-1">Recruit anyone with your personalized QR-Code or link.</v-list-item-subtitle>
                    <v-list-item-subtitle class="subtitle-1"
                      >Gain rewards for each redeemed Code. Also gain more rewards for each player your friends recruited.</v-list-item-subtitle
                    >
                  </v-list-item-content>
                </v-list-item>
                <v-divider></v-divider>

                <v-list-item three-line @click="info = 5" :class="{ 'v-list-item--active': info == 5 }">
                  <v-list-item-action class="align-self-center">
                    <v-icon large>fa-fire</v-icon>
                  </v-list-item-action>
                  <v-list-item-content>
                    <v-list-item-title class="text-h5 text--primary font-weight-medium">Hot & Fresh</v-list-item-title>
                    <v-list-item-subtitle class="subtitle-1">Scroll through the hottest or freshest of your chosen Subreddits.</v-list-item-subtitle>
                    <v-list-item-subtitle class="subtitle-1">See the Memes value & trend with one swipe or tap.</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </v-card>

              <v-card height="420px" outlined class="d-flex d-md-none flex-column justify-space-around text-right">
                <v-list-item three-line @click="info = 3" class="d-flex justify-center" :class="{ 'v-list-item--active': info == 3 }">
                  <v-icon>fa-share-alt</v-icon>
                </v-list-item>
                <v-divider></v-divider>

                <v-list-item three-line @click="info = 4" class="d-flex justify-center" :class="{ 'v-list-item--active': info == 4 }">
                  <v-icon>fa-coins</v-icon>
                </v-list-item>
                <v-divider></v-divider>

                <v-list-item three-line @click="info = 5" class="d-flex justify-center" :class="{ 'v-list-item--active': info == 5 }">
                  <v-icon>fa-fire</v-icon>
                </v-list-item>
              </v-card>
            </v-col>
            <v-col col="11" class="d-flex d-md-none pa-1 justify-center">
              <template v-for="(item, i) in infos">
                <v-card v-if="info == i" :key="`mi${i}`" flat outlined>
                  <v-card-text>
                    <p class="text-h5 text--primary font-weight-medium text-center">{{ item.t }}</p>
                    <div class="subtitle-1">
                      <p v-html="item.p.join('<br />')"></p>
                    </div>
                  </v-card-text>
                </v-card>
              </template>
            </v-col>
          </v-row>

          <v-row justify="center">
            <v-col cols="12">
              <v-row justify="center">
                <v-col cols="6" md="2">
                  <v-img class="err-pointer" :src="require('@/assets/assoon.png')" contain height="70" alt="App Store Coming Soon Logo"></v-img>
                </v-col>
                <v-col cols="6" md="2">
                  <a
                    href="https://play.google.com/store/apps/details?id=com.stonks.app&amp;pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1"
                    target="_blank"
                  >
                    <v-img :src="require('@/assets/gplaybadge.png')" alt="Google Play Download Logo" contain height="70"></v-img>
                  </a>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-container>
        <v-row justify="center" class="mt-15">
          <v-col cols="auto" order="2" order-md="1">
            <v-card height="380px" outlined style="overflow: auto; max-width: calc((6rem + 18px) * 3)" class="hideScrollBar rounded-lg">
              <a
                class="twitter-timeline"
                href="https://twitter.com/Stonks_app?ref_src=twsrc%5Etfw"
                data-tweet-limit="10"
                data-width="360"
                data-chrome="nofooter noborders noscrollbar"
                data-show-replies="false"
                data-dnt="false"
                data-theme="dark"
              >
                Tweets by Stonks_app
              </a>
              <script async src="https://platform.twitter.com/widgets.js" charset="utf-8" type="application/javascript" defer></script>
            </v-card>
          </v-col>
          <v-col cols="auto" order="1" order-md="2">
            <v-card dark outlined min-height="380px" class="rounded-lg">
              <v-card-subtitle class="font-weight-bold title py-0 pt-2">Recently shared</v-card-subtitle>
              <div class="d-flex justify-center flex-wrap" style="max-width: calc((6rem + 18px) * 3)">
                <a
                  v-for="(post, i) in recents"
                  :key="`p${i}`"
                  :href="`/post/${post.id}`"
                  class="recentPost"
                  :style="`background-image: url('${post.thumbnail}')`"
                  :title="`${post.title}`"
                  target="_blank"
                ></a>
              </div>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </v-main>
  </v-template>
</template>

<script>
export default {
  name: "StonksPro",
  metaInfo: {
    title: "StonksPro",
    htmlAttrs: {
      lang: "en",
    },
    meta: [
      { charset: "utf-8" },
      { name: "description", content: "StonksPro | Blast through Memes TO THE MOON!" },

      { property: "og:title", content: "StonksPro" },
      { property: "og:description", content: "StonksPro | Blast through Memes TO THE MOON!" },
      { property: "og:image", content: "https://stonks.app/stonksPics/banner01.png" },
      { property: "og:url", content: "https://stonks.app/" },

      { property: "fb:app_id", content: "1575230175940823" },

      { property: "twitter:title", content: "StonksPro" },
      { property: "twitter:description", content: "StonksPro | Blast through Memes TO THE MOON!" },
      { property: "twitter:image", content: "https://stonks.app/stonksPics/banner01.png" },
      { property: "twitter:card", content: "summary_large_image" },
      { property: "twitter:site", content: "@Stonks_app" },
    ],
  },
  data: () => ({
    bannerHeight: 650,
    dockNav: false,
    mockupVer: 0,
    info: 0,
    recents: {},

    infos: [
      {
        t: "Settings",
        p: [
          "Find useful Settings such as Language, Used Reddit Markets, Analytics Preference and FAQ.",
          "Supported Languages are: English, German, Korean, Spanish, Portuguese, French",
        ],
      },
      { t: "Custom Subreddits", p: ["Decide in which Subreddits you want to invest.", "Add, Delete or disable any Subreddit."] },
      { t: "History", p: ["See all your previously sold Shares.", "Tapping the Picture in each row reveals more details."] },
      { t: "Share & Visit", p: ["Jump directly to the Reddit Page of any Meme.", "Share any Meme with a generated short link."] },
      {
        t: "Recruit Friends for Rewards",
        p: ["Recruit anyone with your personalized QR-Code or link.", "Gain rewards for each redeemed Code.", "Also gain more rewards for each player your friends recruited."],
      },
      { t: "Hot & Fresh", p: ["Scroll through the hottest or freshest of your chosen Subreddits.", "See the Memes value & trend with one swipe or tap."] },
    ],
  }),
  created() {
    window.addEventListener("scroll", this.handleScroll);

    const darkMediaQuery = window.matchMedia("(prefers-color-scheme: dark)");
    if (darkMediaQuery.matches) {
      setTimeout(() => {
        this.$vuetify.theme.dark = true;
      }, 0);
    }
    darkMediaQuery.addEventListener("change", (e) => {
      try {
        this.$vuetify.theme.dark = e.target.matches;
      } catch (err) {}
    });
  },
  destroyed() {
    window.removeEventListener("scroll", this.handleScroll);
  },
  mounted() {
    this.$nextTick(function () {
      this.handleScroll();
      this.getRecents();
    });
  },
  methods: {
    handleScroll(event) {
      this.dockNav = window.scrollY > this.bannerHeight * 0.8;

      if (window.scrollY > (this.$refs.pos3.offsetTop + this.$refs.pos2.clientHeight) * 0.75) {
        this.mockupVer = 3;
      } else if (window.scrollY > (this.$refs.pos2.offsetTop + this.$refs.pos2.clientHeight) * 0.6) {
        this.mockupVer = 2;
      } else if (window.scrollY > this.bannerHeight * 0.7) {
        this.mockupVer = 1;
      } else {
        this.mockupVer = 0;
      }
    },

    darkMode() {
      this.$vuetify.theme.dark = !this.$vuetify.theme.dark;
    },

    scrollTo(n) {
      this.$vuetify.goTo(n, {
        duration: 500,
        easing: "easeInOutCubic",
      });
    },

    getRecents() {
      fetch("/post/recents.json")
        .then((response) => response.json())
        .then(({ recents }) => {
          // console.log(recents);
          this.recents = recents;
        });
    },
  },
};
</script>

<style scoped>
.bg-wrapper {
  position: absolute;
  top: 0;
  padding-top: 0;
  height: 100vh;
  width: 100%;
  pointer-events: none;
  margin: 0;
  padding: 0;
}

.mini-bg {
  display: none;
}

.bgHolder {
  position: relative;
  display: flex;
  justify-content: center;
  margin: 0;
  padding: 0;
  height: 750px;
  margin-bottom: -100px;
  overflow: hidden !important;
}
.bhImg {
  position: absolute;
  height: 900px;
  margin-top: -150px;
}
.bgHolder.noncropped {
  height: 800px;
}

.tests {
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
}

.intro-bg .intro-imgwrap {
  position: relative;
}

.intro-bg .intro-imgwrap .intro-img {
  position: absolute;
}

.sticky-wrapper {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  /* height: 100vh;
  margin-top: -100vh !important; */

  height: 650px;
  margin-top: -650px !important;
  pointer-events: none;
}

.head-nav {
  top: 0;
  position: sticky;
  height: 90px;
  margin-top: -25px !important;
}

.nav-wrap {
  position: relative;
  height: 100%;
  height: 86px;
}
.head-nav2 {
  display: none;
}
.nav-wrap > div {
  position: absolute;
}

.nav-fg,
.nav-bg {
  height: 100%;
  width: 100%;
}
.nav-fg {
  height: 100%;
  padding-left: 2em;
  transition: 1s;
}
.nav-fg > * {
  transition: 0.35s;
}
.nav-sp {
  flex-grow: 0 !important;
}
.nav-sp.docked {
  flex-grow: 1 !important;
}

.nav-bg {
  display: grid;
  align-items: center;
  width: 150vw;
  margin-left: -50vw;
}
.nav-bg div {
  background-color: #272727;
  height: 50%;
  transition: 0.5s;
}
.nav-bg.docked div {
  height: 100%;
}

.logo-wrap,
.play-wrap {
  height: 86px;
  box-sizing: border-box;
  padding: 3px;
  border-radius: 10px;
}

.logo-wrap img {
  background-color: #000;
  height: 80px;
  border-radius: 10px;
}

.nav-btn {
  display: flex;
  align-items: center;
  justify-content: center;
}

.play-wrap img {
  height: 80px;
  border-radius: 10px;
}

.ovh {
  overflow: hidden !important;
}

.doFloat1 {
  animation: float1 4s ease-in-out infinite;
}

.doFloat2 {
  animation: float2 4s ease-in-out infinite;
}

@keyframes float1 {
  0% {
    transform: translatey(0px);
  }
  50% {
    transform: translatey(-3px);
  }
  100% {
    transform: translatey(0px);
  }
}

@keyframes float2 {
  0% {
    transform: translatex(0px) translatey(0px) scale(1);
  }
  50% {
    transform: translatex(-5px) translatey(-3px) scale(1.005);
  }
  100% {
    transform: translatex(0px) translatey(0px) scale(1);
  }
}

/************************** Phone */

.mockup-wrap {
  margin-top: 86px;
  position: relative;
}
.mockup-wrap2 {
  position: absolute;
  left: 50%;
}
.mp {
  transition: 0.6s;
}
.mp1 {
  transform: translate(0%, -300%);
  z-index: 3;
}
.mp2 {
  transform: translate(0%, -200%);
  z-index: 2;
}
.mp3 {
  transform: translate(0%, -100%);
  z-index: 1;
}
.mp4 {
  z-index: 1;
  opacity: 0;
}

.frame {
  position: relative;
  text-align: center;
  transition: 0.35s;
}

.frame-minimal-phone {
  border-radius: 24px;
  max-width: calc(360px * 0.5);
  max-height: calc(723px * 0.5);
  margin: 0 auto;
  padding: 8px;
  padding-top: 12px;
  padding-bottom: 50px;
  background-image: linear-gradient(to right, #434343 0%, black 100%);
  /* box-shadow: 0px 8px 16px 0px rgba(18, 1, 64, 0.1); */
  box-shadow: 0px 6px 6px -3px rgba(0, 0, 0, 0.05), 0px 10px 14px 1px rgba(0, 0, 0, 0.05), 0px 4px 18px 3px rgba(0, 0, 0, 0.05) !important;
}
.frame-minimal-phone::before {
  content: "";
  position: absolute;
  top: 5px;
  left: 50%;
  margin-left: -4px;
  display: inline-block;
  width: 8px;
  height: 8px;
  border-radius: 8px;
  background-color: rgba(127, 127, 127, 0.25);
}
.frame-minimal-phone img {
  border-radius: 16px;
  max-width: 100%;
  max-height: 100%;
}

.frame-minimal {
  max-width: calc(360px * 0.5);
  max-height: calc(723px * 0.5);
  margin: 0 auto;
  padding: 8px;
  padding-top: 20px;
}
.frame-minimal img {
  max-width: 100%;
  max-height: 100%;
}
/****
 * transform: translate(0%, 0px) rotate(-4e-05deg) rotateY(15deg) rotateX(9.99994deg);
 */

.mockup-ver0 .frame {
  max-width: calc(360px * 0.4);
  max-height: calc(723px * 0.4);
  transform: translate(40%, 25px) rotateY(7deg) rotateX(10deg) rotateZ(7deg);
}

.mockup-ver0 .frame-minimal-phone img {
  border-radius: 8px;
}
.mockup-ver0 .mockup-wrap {
  animation: float2 4s ease-in-out infinite;
}

.mockup-ver1 .frame {
  transition: 0.5s;
  max-width: calc(360px * 0.7);
  max-height: calc(723px * 0.7);
  transform: translate(-150%, 50px) rotateY(7deg) rotateX(0deg) rotateZ(-2deg);
}

.mockup-ver1 .mp4 {
  transform: translate(-80%, 0%) rotate(-2deg);
  opacity: 1;
}

.mockup-ver2 .frame {
  transition: 0.2s;
  max-width: calc(360px * 0.6);
  max-height: calc(723px * 0.6);
  transform: translate(10%, 50px);
}
.mockup-ver2 .mp2 {
  transform: translate(100%, -200%) rotate(2deg);
}
.mockup-ver2 .mp3 {
  transform: translate(200%, -98%) rotate(4deg);
}
.mockup-ver2 .frame-minimal-phone {
  box-shadow: 0px 6px 6px -3px rgb(0 0 0 / 20%), 0px 10px 14px 1px rgb(0 0 0 / 14%), 0px 4px 18px 3px rgb(0 0 0 / 12%) !important;
}

.mockup-ver3 {
  height: 750px;
}
.mockup-ver3 .frame {
  max-width: calc(360px * 0.7);
  max-height: calc(723px * 0.7);
  transform: translate(-50%, 30px);
}
.mb-50 {
  margin-bottom: 50px !important;
}

.frame {
  overflow: hidden;
}

.frame-minimal-phone .scrollLayerwrap {
  position: relative;
  border-radius: 16px;
  overflow: hidden;
  width: 100%;
  height: calc((723px * 0.7) - 24px);
  margin-top: calc((-723px * 0.7) + 16px);
  border-radius: 16px;
  z-index: -1;
}

.frame-minimal-phone .scrollLayerwrap img {
  z-index: -1;
  width: 100%;
  max-height: unset;
}
.mockup-ver3 .scrollAni {
  animation: scrollUpDown 20s linear infinite;
}
.mockup-ver3 .scrollAni2 {
  animation: scrollUpDown2 20s linear infinite;
}

@keyframes scrollUpDown {
  0%,
  20% {
    transform: translatey(0px);
  }
  50%,
  70% {
    transform: translatey(calc(-100% + (723px * 0.7) - 24px));
  }
  100% {
    transform: translatey(0px);
  }
}
@keyframes scrollUpDown2 {
  0%,
  10% {
    transform: translatey(0px);
  }
  50% {
    transform: translatey(calc(-100% + (723px * 0.7) - 24px));
  }
  100% {
    transform: translatey(0px);
  }
}

/******************* */

.iconBox {
  padding: 5px;
  border-radius: 5px;
  height: 60px;
  width: 60px;
  border: 1px solid;
}

.card-wrap > div + div {
  margin-top: 10px;
  margin-bottom: 10px;
}

.err-pointer {
  cursor: not-allowed;
}

/******************* */
.iconGridsWrap {
  position: relative;
  border-radius: 5px;
}
.iconGridsWrap > .iconGrid {
  position: absolute;
  border-radius: 5px;
}
.iconGrid {
  display: grid;
  height: 100%;
  width: 100%;
}
.iconGrid.v3x3 {
  grid-template-columns: 4fr 4fr 4fr;
  grid-template-rows: 4fr 4fr 4fr;
}
.iconGrid.v4x4 {
  grid-template-columns: 3fr 3fr 3fr 3fr;
  grid-template-rows: 3fr 3fr 3fr 3fr;
}

.h100 {
  height: 100px;
  width: 100px;
}
.h80 {
  height: 80px;
  width: 80px;
}

/** */
.theme--dark .tborder {
  border: thin solid rgba(255, 255, 255, 0.12);
}
.theme--light .tborder {
  border: thin solid rgba(0, 0, 0, 0.12);
}
.theme--dark .tborder2 {
  border: thin solid rgba(255, 255, 255, 0.3);
}
.theme--light .tborder2 {
  border: thin solid rgba(0, 0, 0, 0.3);
}

.scollup-btn {
  position: fixed;
  z-index: 500;
  bottom: 10px;
  right: 10px;
  opacity: 0.6;
}

/******************************* */

.recents {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 0.8rem;
  padding: 0.8rem;
}

.recentPost {
  border-radius: 6px;
  height: 6rem;
  width: 6rem;
  background-repeat: no-repeat;
  background-size: cover;
  margin: 8px;
}

.hideScrollBar {
  scrollbar-width: none;
}

.hideScrollBar::-webkit-scrollbar {
  display: none;
}

.oz {
  opacity: 0;
}

.infoMidCard {
  height: 500px;
}

@media screen and (max-width: 960px) {
  .hideOnMd,
  .bg-wrapper,
  .bgHolder,
  .sticky-wrapper,
  .head-nav {
    display: none !important;
  }

  .mini-bg {
    display: block;
    position: absolute;
    /* top: 64px; */
    padding-top: 0;
    width: 100%;
    pointer-events: none;
    margin: 0;
    padding: 0;
  }

  .mini-bg .minHolder {
    /* height: 300px; */
    margin-bottom: 0;
    overflow: hidden !important;
    pointer-events: none;
  }
  .mini-bg .minHolder img,
  .min-spacer img {
    display: block;
    max-width: 100%;
    /* max-height: 350px; */
    width: auto;
    height: auto;
    width: 100%;
  }
  .min-spacer {
    /* margin-top: 64px; */
    width: 100vw;
    margin-left: -24px;
    overflow: hidden;
  }
  .min-spacer img {
    width: 100%;
    opacity: 0;
  }

  .spacer-bar {
    position: relative;
    height: 10px;
    background: red;
    width: 80%;
  }
  .spacer-bar div {
    position: absolute;
    height: 20px;
    width: 100vw;
    background: #edecec;
    margin-left: -24px;
  }
  .theme--dark .spacer-bar div {
    background: #272727;
  }

  .head-nav2 {
    display: block;
  }
  .nav-wrap {
    height: 100%;
  }
  .nav-fg {
    align-content: left;
  }
  .nav-bg div {
    height: 100%;
  }
  .nav-sp.docked {
    display: none;
  }
  .logo-wrap,
  .play-wrap {
    height: 100%;
    box-sizing: border-box;
    padding: 3px;
    border-radius: 10px;
  }
  .logo-wrap img {
    height: 100%;
  }
  .play-wrap img {
    height: 100%;
  }

  .gplay-wrap {
    height: 100%;
    box-sizing: border-box;
    padding: 3px 5px;
  }
  .gplay-wrap img {
    height: 100%;
  }

  .igw2 {
    display: flex;
    justify-content: space-around;
    width: 100%;
  }

  .mockup-wrap4 {
    display: flex;
    /* max-width: 300px; */
    pointer-events: none;
  }

  .mockup-wrap4.v1 .frame-minimal {
    transform: rotate(8deg) translateX(0%);
  }
  .mockup-wrap4.v1 .frame-minimal-phone.a1 {
    transform: rotate(8deg) translateX(-25%) translateY(5px);
  }
  .mockup-wrap4.v1 .frame-minimal-phone.a2 {
    transform: rotate(5deg) translateX(-25%) translateY(5px);
  }

  .mockup-wrap4.v2 .frame-minimal-phone.a1 {
    transform: rotate(2deg) translateY(5px) translateX(5px);
  }
  .mockup-wrap4.v2 .frame-minimal-phone.a2 {
    transform: rotate(-2deg) translateX(calc(100% - 5px)) translateY(5px);
  }
  .mockup-wrap4.v2 .frame-minimal-phone.a3 {
    transform: translateX(-100%);
  }
  .infoMidCard {
    height: auto;
  }

  .mockup-wrap4 .frame-minimal-phone,
  .mockup-wrap4 .frame-minimal {
    max-width: calc(360px * 0.3);
    max-height: calc((723px * 0.3));
    padding: 6px;
    padding-top: 10px;
    border-radius: 16px;
  }
  .mockup-wrap4 .frame-minimal-phone img {
    border-radius: 12px;
  }

  .mockup-wrap4 .frame-minimal-phone::before {
    width: 5px;
    height: 5px;
    margin-left: -2px;
    top: 3px;
  }

  .mockup-wrap3 {
    position: relative;
    z-index: 0;
    overflow: hidden;
    border-radius: 16px;
    pointer-events: none;
  }
  .mockup-wrap3 .scrollLayerwrap {
    position: absolute;
    max-width: calc(360px * 0.5);
    max-height: calc((723px * 0.5) - 24px);
    margin-top: calc((-723px * 0.5) + 16px);
  }

  .scrollAni {
    animation: scrollUpDown3 20s linear infinite;
  }
  .scrollAni2 {
    animation: scrollUpDown3 20s linear infinite;
  }

  @keyframes scrollUpDown3 {
    0%,
    20% {
      transform: translatey(0px);
    }
    50%,
    70% {
      transform: translatey(calc(-100% + (723px * 0.5) - 24px));
    }
    100% {
      transform: translatey(0px);
    }
  }

  .recentPost {
    height: 5rem;
    width: 5rem;
  }
}
</style>
